<template>
    <v-card color="error white--text" height="300" class="elevation-24" v-if="items.length > 0">
        <v-card-title class="white--text">
            <v-badge color="white red--text" :content="items ? items.length : '-'" inline>
                Gateways com alarme
            </v-badge>
        </v-card-title>
        <v-card-text>
            <v-flex xs12>
                <v-progress-linear progress color="primary" :value="timerProgress" />
            </v-flex>
            <v-data-table :headers="headers" :items="items" class="elevation-2" hide-default-footer :items-per-page.sync="page">
                <template v-slot:[`item.id`]="{ item }">
                    <v-btn v-if="item.id" class="success" x-small dark :href="href(`/board/${item.name}`)" target="_blank"> Ver alarmes </v-btn>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
export default {

    data() {
        return {
            page: -1,
            items: [],
            timer: null,
            timerProgress: 100,
            loading: true,
            headers: [
                { text: "Identificador", value: "name", align: "right", sortable: false },
                { text: "-", value: "id", align: "center", sortable: false },
            ],
        };
    },

    created() {
        this.update();
        this.timer = setInterval(this.timerTick, 300);
    },

    methods: {
        href(url) {
            return `${window.location.origin}${url}`;
        },
        timerTick() {
            if (this.timerProgress > 0) {
                this.timerProgress--;
            } else {
                this.update();
                this.timerProgress = 100;
            }
        },
        update() {
            this.loading = true;
            this.$http
                .get(`api/v3/board/error`, { showLoading: false })
                .then((result) => {
                    this.items = result;
                }).catch(() => {
                    this.$eventHub.$emit("msgError", "Erro ao buscar gateways.");
                }).finally(() => this.loading = false);
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
}
</script>
<style scoped>
.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}
</style>
